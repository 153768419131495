import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

const initialState = {
  token: null,
  id: null,
  role_id: null,
  isLoading: false,
  message: "",
  isSuccess: false,
};

export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (err) {
    const message = err.response || err.response.data;
    return thunkAPI.rejectWithValue(message);
  }
});

export const verifyOtp = createAsyncThunk(
  "auth/verify-otp",
  async (user, thunkAPI) => {
    try {
      return await authService.verifyOtp(user);
    } catch (err) {
      const message = err.response || err.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/sign_up",
  async (user, thunkAPI) => {
    try {
      return await authService.signUp(user);
    } catch (err) {
      const message = err.response || err.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    return await authService.logout();
  } catch (err) {
    const message = err.response || err.response.data;
    return thunkAPI.rejectWithValue(message);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.token = null;
      state.id = null;
      state.role_id = null;
      state.isLoading = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.token = null;
        state.isLoading = false;
        state.message = action.payload || "Sign Up failed";
        state.isSuccess = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        // state.token = action.payload.token;
        // state.id = action.payload.data.id;
        // state.role_id = action.payload.data.role_id;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(login.rejected, (state, action) => {
        // state.token = null;
        // state.id = null;
        // state.role_id = null;
        state.isLoading = false;
        state.message = action.payload || "Login failed";
      })
      .addCase(verifyOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        // state.token = action.payload.token;
        // state.id = action.payload.data.id;
        // state.role_id = action.payload.data.role_id;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        // state.token = null;
        // state.id = null;
        // state.role_id = null;
        state.isLoading = false;
        state.message = action.payload || "Login failed";
      })
      .addCase(logout.fulfilled, (state) => {
        state.token = null;
        state.id = null;
        state.role_id = null;
        state.isLoading = false;
        state.message = "";
      })
      .addCase(logout.rejected, (state, action) => {
        state.message = action.payload || "Logout failed";
      });
  },
});

export const { reset } = authSlice.actions;

export default authSlice.reducer;
export const selectCurrentUser = (state) => state.auth;
