import { Link } from "react-router-dom";
import Image from "../../../components/Image/Image";
import Icon from "../../../components/Icon/Icon";
// import { Content } from "next/font/google";
const GOOGLE_FORM= process.env.REACT_APP_GOOGLE_FORM
const solutions = [
    {
        title: "Streamline your Picking Process ",
        stage: "Picking",
        content: "Generate picklists, batch them by products for faster picking. Assign to available pickers and use the mobile app for pickers for a paperless picking process.",
        content2: "The app automatically generates an optimized picking order based on bin locations to minimize time and maximize accuracy.",
        image: "/images/contents/morfdesk/picking-process.png",
        url: "/solutions",
    },
    {
        title: "Enhance Packing Accuracy with visual monitoring",
        stage: "Packing",
        content:
            "Pick Pack Go allows you to capture photos & videos on the packing station and link them with orders to minimize RTO due to wrong/missing items & identify any packaging issues.",
        image: "/images/contents/morfdesk/packing-process.png",
        url: "/solutions",
    },
    {
        title: "Maximize Stock Efficiency",
        stage: "Inventory",
        content:
            "Stay ahead of stock challenges with our real-time tracking and analytics, ensuring you avoid both stockouts and overstocks.",
        content2: "Experience seamless order processing through barcode scanning and order verification, streamlining your operations. With automation rules, tagging, and filtering capabilities, reclaim your time and let our system handle the complexities for you",
        image: "/images/contents/morfdesk/inventory-process.png",
        url: "/solutions",
    },
];



const Solutions = () => (
    <div className="section">
        <div className="container">
            <div className="mb-30 xl:mb-24 lg:mb-18 md:mb-12">
                <div className="stage">Solutions</div>
                <div className="mb-4 text-h2 xl:text-h3 md:text-h4">
                    Open Your Business Account Online
                </div>
                <div className="max-w-[48rem] text-greyscale-400 md:text-lg">
                    Keep your business account and all your finance needs safely
                    organized under one roof. Manage money quickly, easily &
                    efficiently. Whether you’re alone or leading a team.
                </div>
            </div>
            <div className="space-y-30 xl:space-y-24 lg:space-y-20 md:space-y-12">
                {solutions.map((solution, index) => (
                    <div
                        className="flex items-center -mx-20 odd:flex-row-reverse 2xl:-mx-10 xl:-mx-5 lg:block lg:mx-0"
                        key={index}
                    >
                        <div className="w-[calc(50%-5rem)] mx-20 2xl:w-[calc(50%-2.5rem)] 2xl:mx-10 xl:w-[calc(50%-1.25rem)] xl:mx-5 lg:w-full lg:mx-0 lg:mb-10">
                            <div className="stage">{solution.stage}</div>
                            <div className="mb-4 text-h2 xl:text-h3 md:text-h4">
                                {solution.title}
                            </div>
                            <div className="mb-12 text-greyscale-400 md:mb-6 md:text-lg">
                                {solution.content}<br /><br />
                                {solution.content2}
                            </div>
                            <Link
                                className="btn-primary pr-5 md:w-full"
                                to={GOOGLE_FORM}
                            >
                                <span>Learn More</span>
                                <Icon className="w-5 h-5" name="arrow-right" />
                            </Link>
                        </div>
                        <div className="w-[calc(50%-5rem)] mx-20 bg-greyscale-25 border border-greyscale-100 2xl:w-[calc(50%-2.5rem)] 2xl:mx-10 xl:w-[calc(50%-1.25rem)] xl:mx-5 lg:w-full lg:mx-0">
                            <Image
                                className="w-full"
                                src={solution.image}
                                width={500}
                                height={450}
                                alt=""
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default Solutions;
