import axios from "axios";
const env = process.env;
const instance = axios.create({
    baseURL: env.REACT_APP_API_PATH_LOCAL,
    timeout: 100000,
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            console.log('Unauthorized error occurred.');
        }
        return Promise.reject(error);
    }
);

export default instance;
