import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import LandingPageIndex from "./LandingPage/LandingPageIndex";
import AllOrders from "./screens/Orders/AllOrders/AllOrders";
import ProctedRoute from "./features/auth/ProtectedRoute";
import PublicRoute from "./features/auth/PublicRoute";
import Staffsindex from "./screens/Staff/Staffsindex";
import ScanOredrs from "./screens/ScanOrders/ScanOrderIndex";
import AddStaffMember from "./screens/Staff/AddStaffMember/AddStaffMember";
import { MenuProvider } from "./context/Roles/MenuContext";
import GeneratePickListByOrderPrintView from "./screens/Orders/AllOrders/Filters/GeneratePickListByOrderPrintView";
import GeneratePickListByProductPrintView from "./screens/Orders/AllOrders/Filters/GeneratePickListByProductPrintView";
import OrderDetails from "./screens/Orders/AllOrders/Filters/OrderDetails";

import { FormikProvider } from "./screens/Settings/context/SettingsContext";
function App() {
  return (
    <Routes>
      <Route index path="/*" element={<LandingPageIndex />} />
      {/* <Route element={<ProctedRoute />}>
       
        <Route
          path="/orders/allorders"
          element={
            <Page title="All Orders">
              <AllOrders />
            </Page>
          }
        />
        <Route
          path="/order-details/:id"
          element={
            <Page>
              <OrderDetails />
            </Page>
          }
        />
        <Route
          path="/generatePickList"
          element={<GeneratePickListByOrderPrintView />}
        ></Route>
        <Route
          path="/generatePickListByProduct"
          element={<GeneratePickListByProductPrintView />}
        ></Route>
        <Route
          path="/staffs"
          element={
            <Page title="Staff list">
              <Staffsindex />
            </Page>
          }
        />
        <Route
          path="staff/add"
          element={
            <Page title="Add Member">
              <AddStaffMember />
            </Page>
          }
        />
        <Route
          path="/staff/edit/:id"
          element={
            <Page title="Add Member">
              <AddStaffMember />
            </Page>
          }
        />

        <Route
          path="settings"
          element={
            <Page title="Settings">
              <MenuProvider>
                {" "}
                <FormikProvider>
                  <Settings />
                </FormikProvider>
              </MenuProvider>
            </Page>
          }
        />

        <Route
          path="notification"
          element={
            <Page title="Notification">
              <Notification />
            </Page>
          }
        />
        <Route
          path="/scanorder"
          element={
            <Page title="Scan Order">
              <ScanOredrs />
            </Page>
          }
        />

        <Route
          path="/scanorder"
          element={
            <Page title="Scan Order">
              <ScanOredrs />
            </Page>
          }
        />
   
      </Route> */}
      {/* <Route element={<PublicRoute />}>
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-in" element={<SignIn />} />
      
      </Route> */}
    </Routes>
  );
}

export default App;
