import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import Loader from "../../../components/Loader";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { verifyOtp, reset } from "../../../features/auth/authSlice";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

const Code = ({ userData }) => {
  const { isSuccess, message, isLoading, flag } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const [code, setCode] = useState(["", "", "", ""]);

  const inputs = useRef([]);
  const navigate = useNavigate();
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (index < inputs.current.length - 1) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      if (code[index]) {
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
      } else if (index > 0) {
        inputs.current[index - 1].focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < inputs.current.length - 1) {
      inputs.current[index + 1].focus();
    }
  };

  const handleSubmit = async () => {
    try {
      const otp = code.join("");

      const formData = {
        otp: parseInt(otp),
        email: userData.email,
      };
      dispatch(verifyOtp(formData));
    } catch (err) {}
  };

  const isButtonDisabled = code.some((num) => num === "");
  useEffect(() => {
    if (isSuccess) {
      Toast.fire({
        icon: "success",
        title: `Login success`,
      });

      dispatch(reset());
      navigate("/orders/allorders");
    } else if (message) {
      Toast.fire({
        icon: "error",
        title: message.data.err,
      });
    }
  }, [isSuccess, message]);
  return (
    <div className={styles.code}>
      <div className={styles.body}>
        <div className={styles.info}>
          We just sent you a verify code. Check your inbox to get it.
        </div>
        <div className={styles.fieldset}>
          {code.map((num, index) => (
            <div className={styles.field} key={index}>
              <input
                className={styles.input}
                name={`number${index + 1}`}
                type="tel"
                autoComplete="off"
                required
                value={num}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputs.current[index] = el)}
              />
            </div>
          ))}
        </div>
        <div className={styles.errorNote}>
          The code you entered is incorrect.
        </div>
        <button
          className={cn("button", styles.button)}
          onClick={handleSubmit}
          disabled={isButtonDisabled || isLoading}
        >
          {isLoading ? (
            <Loader className={styles.loader} white />
          ) : (
            <span>Continue</span>
          )}
        </button>
        <div className={styles.note}>
          This site is protected by reCAPTCHA and the Google Privacy Policy.
        </div>
      </div>
    </div>
  );
};

export default Code;
