export const reviews = [
    {
        id: "0",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-1.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "1",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-2.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "2",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-3.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "3",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-1.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "4",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-2.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "5",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-3.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "6",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-1.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "7",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-2.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "8",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-3.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
    {
        id: "9",
        author: "Alex Bergwijn",
        position: "Accounting at Mailchimp",
        avatar: "/images/content/avatar-1.jpg",
        content:
            "When I signed up with Lendwise it was a no-brainer. It's been one of the best decisions I've made to ensure my finances are on point.",
    },
];
