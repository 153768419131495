import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
// import TextInput from "./TextInput";
import TextInput from "../../../components/TextInput";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, reset } from "../../../features/auth/authSlice";
import Image from "../../../components/Image";

import * as Yup from "yup";
import DarkLogo from "../../../assets/images/ppg_logo_in_dark.svg";

import Swal from "sweetalert2";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});
const SignIn = ({ onConfirm, setUserData }) => {
  const heightWindow = use100vh();
  const [email, setEmail] = useState("");

  const [validationErrors, setValidationErrors] = useState({});

  const { token, isSuccess, message, isLoading } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  useEffect(() => {}, [token, message]);

  useEffect(() => {
    setValidationErrors({});
  }, [email]);

  useEffect(() => {
    if (isSuccess) {
      Toast.fire({
        icon: "success",
        title: `OTP send to your email address`,
      });

      onConfirm();
    } else if (message) {
      Toast.fire({
        icon: "error",
        title: message.data.err,
      });
    }
    dispatch(reset());
  }, [isSuccess, message]);
  const handleSignIn = async () => {
    try {
      await validationSchema.validate({ email }, { abortEarly: false });
      dispatch(login({ email }));
      setUserData({ email: email });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = {};
        error.inner.forEach((fieldError) => {
          errors[fieldError.path] = fieldError.message;
        });
        setValidationErrors(errors);
      }
    }
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        {/* <Link className={styles.logo} to="/dsdsd">
          <Image
            className={styles.pic}
            src="/logo.png"
            srcDark={DarkLogo}
            alt="Core"
          />
        </Link> */}

        <div className={styles.body}>
          <TextInput
            className={cn(styles.field, {
              [styles.validationError]: validationErrors.email,
            })}
            name="email"
            type="email"
            placeholder="Your email"
            required
            icon="mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {validationErrors.email && (
            <div className={styles.error}>{validationErrors.email}</div>
          )}

          {validationErrors.password && (
            <div className={styles.error}>{validationErrors.password}</div>
          )}
          <button
            className={cn("button", styles.button)}
            onClick={handleSignIn}
          >
            {isLoading ? <Loader /> : "Sign in"}
          </button>
          <div className={styles.note}>
            By Signing in you accept our{" "}
            <span className={cn(styles.note, "text-black font-bold")}>
              Terms of Service & Privacy Policy
            </span>
          </div>
          {/* <div className={styles.info}>
            Don’t have an account?{" "}
            <Link className={styles.link} style={{ color: "#2A85FF" }} to="/">
              Sign up
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
