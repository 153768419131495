import React, { useEffect } from "react";
import Dividers from "../Dividers/Dividers";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { enablePageScroll, clearQueueScrollLocks } from "scroll-lock";

const Layout = ({
    paddingTop,
    classHeader,
    darkHeader,
    children,
}) => {
    const pathname = window.location.pathname; // Get current pathname

    useEffect(() => {
        clearQueueScrollLocks();
        enablePageScroll();
    }, [pathname]);

    return (
        <>
            <Header className={classHeader} dark={darkHeader} />
            <Dividers
                className="flex flex-col min-h-screen overflow-hidden"
                classInner="flex flex-col grow"
                hero={paddingTop}
            >
                {children}
                <Footer />
            </Dividers>
        </>
    );
};

export default Layout;
