import {
  Button,
  Input,
  Select,
  Form,
  Divider,
  DatePicker,
  Radio,
  Space,
  Spin,
  message,
  ConfigProvider,
} from "antd";
import cn from "classnames";
import { Formik } from "formik";
import dayjs from "dayjs";
import styles from "../Released.module.sass";
import {
  GET_ORDER_FILTER_OPTIONS_BY_TYPE,
  GET_CUSTOM_FILTER_BY_ID,
  CREATE_CUSTOM_FILTER,
  UPDATE_CUSTOM_FILTER,
  GET_FETCH_TAG_SUGGESTION,
} from "../../../../API/Orders";
import moment from "moment";
import { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import useDarkMode from "use-dark-mode";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm";
const dateFormatOne = "YYYY-MM-DD";
const defaultInitialValues = {
  filterName: "",
  fulfillmentStatus: null,
  taggedWith: null,
  notTaggedWith: null,
  deliveryStatus: null,
  financialStatus: null,
  appName: null,
  channelName: null,
  returnStatus: null,
  risk: null,
  fulfillmentAt: null,
  fromDate: null,
  toDate: null,
};

const NewCustomFilter = ({
  isNewFilterModalOpen,
  handleNewFilterModalOk,
  handleNewFilterModalCancel,
  editFilterId,
}) => {
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [fulfillmentAt, setFulfillmentAt] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [valueFilterByDate, setValueFilterByDate] = useState(0);
  const [fulfilmentOptions, setFulfilmentOptions] = useState([]);
  const [deliveryStatusOptions, setDeliveryStatusOptions] = useState([]);
  const [financialStatusOptions, setFinancialStatusOptions] = useState([]);
  const [optionsApp, setOptionsApp] = useState([]);
  const [optionsRisk, setOptionsRisk] = useState([]);
  const [optionsReturn, setOptionsReturn] = useState([]);
  const [taggedWithSuggestions, setTaggedWithSuggestions] = useState([]);
  const [notTaggedWithSuggestions, setNotTaggedWithSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const darkMode = useDarkMode(false);
  useEffect(() => {
    const fetchFilterDetails = async () => {
      try {
        if (editFilterId) {
          const response = await GET_CUSTOM_FILTER_BY_ID(editFilterId);
          const data = response.data;
          setFromDate(data.fromDate);
          setToDate(data.toDate);
          handleRadioOptions(data.fromDate, data.toDate);
          setInitialValues(mapFilterData(data));
        } else {
          setInitialValues(defaultInitialValues);
        }
      } catch (error) {
        message.error("Failed to fetch filter details:", error);
      }
    };

    const mapFilterData = (data) => ({
      filterName: data.custom_filter_name || "",
      fulfillmentStatus: mapArrayToNames(data.fulfillmentStatus),
      taggedWith: mapArrayToNames(data.taggedWith),
      notTaggedWith: mapArrayToNames(data.notTaggedWith),
      deliveryStatus: mapArrayToNames(data.deliveryStatus),
      financialStatus: mapArrayToNames(data.financialStatus),
      appName: mapArrayToNames(data.appName),
      channelName: mapArrayToNames(data.channelName),
      returnStatus: mapArrayToNames(data.returnStatus),
      risk: mapArrayToNames(data.riskLevel),
      fulfillmentAt: data.fulfillmentAt,
      fromDate: data.fromDate,
      toDate: data.toDate,
    });

    const mapArrayToNames = (array) =>
      array ? array.map((item) => item.name) : [];
    fetchFilterDetails();
    fetchFilterOptions();
  }, [editFilterId, fromDate, toDate]);

  const fetchFilterOptions = async () => {
    try {
      const data = await GET_ORDER_FILTER_OPTIONS_BY_TYPE();

      const mapOptions = (options) =>
        Object.entries(options || {}).map(([key, value]) => ({
          label: key,
          value,
        }));

      const fulfilmentOptions = mapOptions(data?.fulfillmentStatus);
      const deliveryStatusOptions = mapOptions(data?.deliveryStatus);
      const financialStatusOptions = mapOptions(data?.financialStatus);
      const optionsApp = mapOptions(data?.app);
      const optionsRisk = mapOptions(data?.risk);
      const optionsReturn = mapOptions(data?.returnStatus);

      setFulfilmentOptions(fulfilmentOptions);
      setDeliveryStatusOptions(deliveryStatusOptions);
      setFinancialStatusOptions(financialStatusOptions);
      setOptionsApp(optionsApp);
      setOptionsRisk(optionsRisk);
      setOptionsReturn(optionsReturn);
    } catch (error) {
      message.error("Failed to fetch filter options:", error);
    }
  };
  const onChangeFulfilledAt = (date) => {
    setFulfillmentAt(date ? date.format(dateFormatOne) : null);
  };

  const onChange = (e) => {
    const selectedValue = e.target.value;
    setValueFilterByDate(selectedValue);

    const d = new Date();
    setToDate(d.toISOString());

    switch (selectedValue) {
      case 1:
        d.setDate(d.getDate() - 1);
        break;
      case 2:
        d.setDate(d.getDate() - 7);
        break;
      case 3:
        d.setDate(d.getDate() - 30);
        break;
      case 4:
        d.setDate(d.getDate() - 90);
        break;
      case 5:
        d.setDate(d.getDate() - 365);
        break;
      default:
        setFromDate(null);
        return;
    }
    setFromDate(d.toISOString());
  };

  const onChangeCustomDate = (dates) => {
    if (dates && dates.length === 2) {
      setToDate(dates[1].toISOString());
      setFromDate(dates[0].toISOString());
    }
  };

  const handleRadioOptions = (fromDate, toDate) => {
    const differenceInDays = Math.abs(
      moment(fromDate, "YYYY-MM-DD").diff(moment(toDate, "YYYY-MM-DD"), "days")
    );

    const radioValue =
      {
        1: 1,
        7: 2,
        30: 3,
        90: 4,
        365: 5,
      }[differenceInDays] || 6;

    setValueFilterByDate(radioValue);
  };

  const handleTaggedWithSearch = async (value) => {
    setLoading(true);
    try {
      const response = await GET_FETCH_TAG_SUGGESTION(value);
      const suggestions = response.data.map((item) => item.tag_name.trim());
      setTaggedWithSuggestions(suggestions);
    } catch (error) {
      message.error("Failed to fetch suggestions for taggedWith:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotTaggedWithSearch = async (value) => {
    setLoading(true);
    try {
      const response = await GET_FETCH_TAG_SUGGESTION(value);
      const suggestions = response.data.map((item) => item.tag_name.trim());
      setNotTaggedWithSuggestions(suggestions);
    } catch (error) {
      message.error("Failed to fetch suggestions for notTaggedWith:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const filteredValues = {
        ...values,
        fulfillmentStatus: filterUndefinedItems(values.fulfillmentStatus),
        taggedWith: filterUndefinedItems(values.taggedWith),
        notTaggedWith: filterUndefinedItems(values.notTaggedWith),
        deliveryStatus: filterUndefinedItems(values.deliveryStatus),
        financialStatus: filterUndefinedItems(values.financialStatus),
        appName: filterUndefinedItems(values.appName),
        channelName: filterUndefinedItems(values.channelName),
        returnStatus: filterUndefinedItems(values.returnStatus),
        risk: filterUndefinedItems(values.risk),
        fulfillmentAt,
        fromDate,
        toDate,
      };

      if (editFilterId) {
        await UPDATE_CUSTOM_FILTER(editFilterId, filteredValues);
      } else {
        await CREATE_CUSTOM_FILTER(filteredValues);
      }
      handleNewFilterModalOk();
    } catch (error) {
      message.error("Failed to submit filter:", error);
    }
  };

  const filterUndefinedItems = (array) =>
    array ? array.filter((item) => item !== undefined) : [];

  const themeConfig = {
    token: {
      colorPrimary: "#1890ff",
      colorTextBase: darkMode.value ? "#ffffff" : "#000000",
      colorBgContainer: darkMode.value ? "#1f1f1f" : "#ffffff",
      colorBgElevated: darkMode.value ? "#2f2f2f" : "#ffffff", // Background for dropdown
      colorText: darkMode.value ? "#ffffff" : "#000000", // Text color in dropdown
      buttonBoxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
    },
  };
  const buttonStyles = {
    boxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
  };
  return (
    <ConfigProvider theme={themeConfig}>
      <Modal
        outerClassName={styles.outer}
        visible={isNewFilterModalOpen}
        onClose={handleNewFilterModalCancel}
        // title={editFilterId ? "Edit Custom Filter" : "New Custom Filter"}
        // open={isNewFilterModalOpen}
        onOk={handleNewFilterModalOk}
        // onCancel={handleNewFilterModalCancel}
      >
        <div className={styles.details}>
          <div className={cn("title-purple", styles.title)}>
            {editFilterId ? "Edit Custom Filter" : "New Custom Filter"}
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <div>
                <Spin spinning={loading}>
                  <Divider />
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                  >
                    {({ values, handleChange, handleBlur, setFieldValue }) => (
                      <Form layout="vertical">
                        <Form.Item label="Filter Name">
                          <Input
                            name="filterName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.filterName}
                          />
                        </Form.Item>
                        <Form.Item label="Fulfillment Status">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            value={values.fulfillmentStatus}
                            onChange={(value) =>
                              setFieldValue("fulfillmentStatus", value)
                            }
                          >
                            {fulfilmentOptions.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Tagged With">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Search and select tags"
                            value={values.taggedWith}
                            onSearch={handleTaggedWithSearch}
                            onChange={(value) =>
                              setFieldValue("taggedWith", value)
                            }
                          >
                            {taggedWithSuggestions.map((tag, index) => (
                              <Select.Option key={index} value={tag}>
                                {tag}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Not Tagged With">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Search and select tags"
                            value={values.notTaggedWith}
                            onSearch={handleNotTaggedWithSearch}
                            onChange={(value) =>
                              setFieldValue("notTaggedWith", value)
                            }
                          >
                            {notTaggedWithSuggestions.map((tag, index) => (
                              <Select.Option key={index} value={tag}>
                                {tag}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Delivery Status">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            value={values.deliveryStatus}
                            onChange={(value) =>
                              setFieldValue("deliveryStatus", value)
                            }
                          >
                            {deliveryStatusOptions.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Financial Status">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            value={values.financialStatus}
                            onChange={(value) =>
                              setFieldValue("financialStatus", value)
                            }
                          >
                            {financialStatusOptions.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="App Name">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            value={values.appName}
                            onChange={(value) =>
                              setFieldValue("appName", value)
                            }
                          >
                            {optionsApp.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Channel Name">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            value={values.channelName}
                            onChange={(value) =>
                              setFieldValue("channelName", value)
                            }
                          >
                            {optionsApp.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Return Status">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            value={values.returnStatus}
                            onChange={(value) =>
                              setFieldValue("returnStatus", value)
                            }
                          >
                            {optionsReturn.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Risk">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            value={values.risk}
                            onChange={(value) => setFieldValue("risk", value)}
                          >
                            {optionsRisk.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Fulfilled At">
                          <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormatOne}
                            value={
                              values.fulfillmentAt
                                ? dayjs(values.fulfillmentAt)
                                : null
                            }
                            onChange={(date) => {
                              setFieldValue(
                                "fulfillmentAt",
                                date ? date.format(dateFormatOne) : null
                              );
                              onChangeFulfilledAt(date);
                            }}
                          />
                        </Form.Item>

                        <Form.Item label="Date Range">
                          <Radio.Group
                            value={valueFilterByDate}
                            onChange={onChange}
                          >
                            <Space direction="vertical">
                              <Radio value={1}>Today</Radio>
                              <Radio value={2}>Last 7 days</Radio>
                              <Radio value={3}>Last 30 days</Radio>
                              <Radio value={4}>Last 90 days</Radio>
                              <Radio value={5}>Last 365 days</Radio>

                              <Radio value={6}>
                                Custom
                                {valueFilterByDate === 6 ? (
                                  <Space direction="vertical" size={12}>
                                    <RangePicker
                                      showTime={{
                                        format: "HH:mm",
                                      }}
                                      format={dateFormat}
                                      onChange={onChangeCustomDate}
                                      defaultValue={[
                                        fromDate
                                          ? dayjs(fromDate, dateFormat)
                                          : null,
                                        toDate
                                          ? dayjs(toDate, dateFormat)
                                          : null,
                                      ]}
                                    />
                                  </Space>
                                ) : null}
                              </Radio>
                              <Button
                                type="primary"
                                onClick={() => setValueFilterByDate(0)}
                                style={buttonStyles}
                              >
                                Clear
                              </Button>
                            </Space>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item>
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button onClick={handleNewFilterModalCancel}>
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => handleSubmit(values)}
                              style={buttonStyles}
                            >
                              Save
                            </Button>
                          </Space>
                        </Form.Item>
                      </Form>
                    )}
                  </Formik>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default NewCustomFilter;
