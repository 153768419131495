import React from 'react'
import cn from "classnames";
import styles from "../Released.module.sass"
import Icon from '../../../../components/Icon';
import { Tooltip } from 'antd';
function Filters({ toggleFilters }) {
    return (

        <>
            <Tooltip title="Filters">
                <div style={{ justifyContent: "end" }}>
                    <div className="relative">
                        <button className={cn(styles.link, styles.active)} onClick={toggleFilters}>
                            <Icon name="filter" size="24" />
                        </button>
                    </div>


                </div>
            </Tooltip>
        </>
    )
}

export default Filters