export const socials = [
    {
        id: "0",
        icon: "linkedin",
        url: "https://www.linkedin.com/company/ui8",
    },
    {
        id: "1",
        icon: "twitter",
        url: "https://twitter.com/ui8",
    },
    {
        id: "2",
        icon: "facebook",
        url: "https://www.facebook.com/ui8.net/",
    },
];
