import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import { signUp, reset } from "../../../features/auth/authSlice";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/Loader";
import Swal from "sweetalert2";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});
const Entry = ({ onConfirm, setUserData }) => {
  const { isSuccess, message, isLoading, flag } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const storeId = queryParams.get("store_id");
  const initialValues = {
    email: "",
    full_name: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().required("Email  is required"),
    full_name: Yup.string().required("Full name  is required"),
  });
  const handleSignUp = async (
    values,
    { setTouched, setErrors, validateForm }
  ) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          name: true,
          cameraSettings: values.cameraSettings.map(() => ({
            camera_id: true,
            purpose_id: true,
          })),
        });
        setErrors(errors);
        return;
      }

      const formData = {
        email: values.email,
        token: storeId,
        full_name: values.full_name,
      };
      dispatch(signUp(formData));
      setUserData({
        full_name: values.full_name,
        email: values.email,
        token: storeId,
      });
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.errors;
        message.error(Object.values(errors[0])[0]);
      } else if (error.response.status === 500) {
        message.error(error.response.data.err);
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      Toast.fire({
        icon: "success",
        title: `OTP send to your email address`,
      });
      onConfirm();
    } else if (message) {
      Toast.fire({
        icon: "error",
        title: message.data.err,
      });
    }
    dispatch(reset());
  }, [isSuccess, message]);
  return (
    <div className={styles.entry}>
      <div className={styles.head}>
        {/* <div className={styles.info}>Sign up with Open account</div> */}
        {/* <div className={styles.btns}>
          <button className={cn("button-stroke", styles.button)}>
            <img src="/images/content/google.svg" alt="Google" />
            Google
          </button>
          <button className={cn("button-stroke", styles.button)}>
            <Image
              className={styles.pic}
              src="/images/content/apple-dark.svg"
              srcDark="/images/content/apple-light.svg"
              alt="Apple"
            />
            Apple ID
          </button>
        </div> */}
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSignUp}
      >
        {({}) => (
          <Form>
            <div className={styles.body}>
              {/* <div className={styles.info}>Or continue with email address</div> */}

              <Field name="email">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    className={styles.field}
                    name="email"
                    type="email"
                    placeholder="Your email"
                    required
                    icon="mail"
                    error={meta.touched || meta.error ? meta.error : ""}
                  />
                )}
              </Field>
              <Field name="full_name">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    className={styles.field}
                    name="full_name"
                    type="text"
                    placeholder="Full Name"
                    required
                    icon="profile-circle"
                    error={meta.touched || meta.error ? meta.error : ""}
                  />
                )}
              </Field>

              <button className={cn("button", styles.button)} type="submit">
                {isLoading ? <Loader /> : " Continue"}
              </button>
              <div className={styles.note}>
                This site is protected by reCAPTCHA and the Google Privacy
                Policy.
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Entry;
