import React, { useState, useRef } from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import Loader from "../../../components/Loader";
import { verify_otp } from "../../../API/Auth";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

const Code = ({ userData }) => {
  const [code, setCode] = useState(["", "", "", ""]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputs = useRef([]);
  const navigate = useNavigate();
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (index < inputs.current.length - 1) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      if (code[index]) {
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
      } else if (index > 0) {
        inputs.current[index - 1].focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < inputs.current.length - 1) {
      inputs.current[index + 1].focus();
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const otp = code.join("");

      const formData = {
        otp: parseInt(otp),
        email: userData.email,
        token: userData.token,
        full_name: userData.full_name,
      };
      const res = await verify_otp(formData);

      if (res.status === 200) {
        Toast.fire({
          icon: "success",
          title: res.data.message,
        });
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.errors;
        Toast.fire({
          icon: "error",
          title: Object.values(errors[0])[0],
        });
      } else if (error.response.status === 500) {
        Toast.fire({
          icon: "error",
          title: error.response.data.err,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const isButtonDisabled = code.some((num) => num === "");

  return (
    <div className={styles.code}>
      <div className={styles.body}>
        <div className={styles.info}>
          We just sent you a verify code. Check your inbox to get it.
        </div>
        <div className={styles.fieldset}>
          {code.map((num, index) => (
            <div className={styles.field} key={index}>
              <input
                className={styles.input}
                name={`number${index + 1}`}
                type="tel"
                autoComplete="off"
                required
                value={num}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputs.current[index] = el)}
              />
            </div>
          ))}
        </div>
        <div className={styles.errorNote}>
          The code you entered is incorrect.
        </div>
        <button
          className={cn("button", styles.button)}
          onClick={handleSubmit}
          disabled={isButtonDisabled || isSubmitting}
        >
          {isSubmitting ? (
            <Loader className={styles.loader} white />
          ) : (
            <span>Continue</span>
          )}
        </button>
        <div className={styles.note}>
          This site is protected by reCAPTCHA and the Google Privacy Policy.
        </div>
      </div>
    </div>
  );
};

export default Code;
